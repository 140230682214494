import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_ACCOUNT;

class Account
{
	enviar_otp(destinatario) {
		return axios.post(baseURL+'/otp',{destinatario: destinatario});
	}

	validar_otp(payload) {
		return axios.post(baseURL+'/otp/validar', payload);
	}
}

export default new Account();