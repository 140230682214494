<template>
  <div>
    <Modal :options="{width: '50vw', close: true}" @close="$emit('close')">
        <div class="title">Asignar Crédito al Cliente</div>
        <div class="body">  
            <div class="row form-group">
              <div class="col-sm-10 texto-menor offset-1">
                  <div class="row header">
                      <div class="col-sm-8">Información</div>
                      <div class="col-sm-4 text-center">Seleccionar</div>
                  </div>
                <template v-if="clientes.length > 0">
                <div v-for="cliente in clientes" :key="cliente.id" class="row row_observation">
                    <div  class="col-sm-8">
                      <p>
                        <strong>Nombre del Cliente: </strong> {{cliente.nombre_completo!== null ? cliente.nombre_completo : 'N/A'}} <br>
                        <strong>RFC: </strong> {{cliente.rfc!== null ? cliente.rfc : 'N/A'}} <br>
                        <strong>CURP: </strong> {{cliente.curp!== null ? cliente.curp : 'N/A'}} <br>
                      </p>
                    </div>
                    <div class="col-sm-4 text-center"> <input v-model="cliente_seleccionado" class="form-check-input" type="radio"  :value="cliente"/></div> 
                </div>
                </template>
                <div v-else class="row">
                    <div class="col-sm-12">No hay informacion registrada</div>
                </div>
              </div>
            </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-12 text-right">
              <button type="button" class="btn btn-primary mr-2" @click="asignar_credito"> Asignar </button>
              <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
            </div>
          </div>
        </div>        
    </Modal>    
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import api from '@/apps/clientes/api/clientes'


export default {

    components:{
        Modal
    },
    props:{
      clientes_encontrados:null,
      solicitud:null,
    },
    data(){
        return{
          clientes:[],
          cliente_seleccionado:null,

        }
    },
    beforeMount(){
      if(this.clientes_encontrados && this.clientes_encontrados.length > 0)
        this.clientes = this.clientes_encontrados;
    },
    methods:{
        async asignar_credito(){
          try {
            // this.$log.info('cliente', this.cliente_seleccionado);
            // this.$log.info('solicitud', this.solicitud);            
            await api.asociar_credito_usuario(this.cliente_seleccionado.id, this.solicitud.id)
            this.$helper.showMessage('Solicitud Asignada','La solicitud ha sido asignada al cliente existente');
            this.$emit('update');
          } catch (e) {
            this.$log.info('error', e);
            this.$helper.showAxiosError(e,'Error');
          }


        }
    },
    watch:{
      clientes_encontrados(){
        if(this.clientes_encontrados && this.clientes_encontrados.length > 0)
          this.clientes = this.clientes_encontrados;
      }
    }

}
</script>

<style lang="scss" scoped>
  .file_list_container {
    overflow: auto;

    .file_list {
      button {
        margin: 3px;
      }
    }

    .file_list:nth-child(2n+1) {
      background-color: #E6E6E6;
    }
  }

  .header {
    background-color: #4D4D4D;
    color: #fff;
    padding: 5px 3px;
  }

  .texto-menor {
    font-size: 0.8em !important;

    button {
      font-size: 0.8em !important;
    }
  }

  .row_observation {
    div {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  .row_observation:nth-child(2n+1) {
    background-color: #E6E6E6;
  }
  
</style>