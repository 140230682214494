<template>
	<div>
		<Modal :options="getModalConfig" @close="modalClose">
			<div class="title" v-html="title"></div>
			<div v-if="hasSlot" class="body">
				<slot/>
			</div>
			<div v-else class="body" v-html="body"></div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="btnsActions(1)">{{buttonAccept}}</button></div>
					<div class="col-sm-3"><button class="btn danger-btn" @click="btnsActions(2)">{{buttonRefuse}}</button></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from './Modal';

	export default {
		components: {
			Modal
		},
		props: {
			title: {
				type: String
				,default: 'Confirmar'
			}
			,body: {
				type: String
				,default: ''
			}
			,buttonAccept: {
				type: String
				,default: 'Aceptar'
			}
			,buttonRefuse: {
				type:String
				,default: 'Cancelar'
			}
			,modalClose: {
				type: Function
				,default: () => {}
			}
			,actionConfirm: {
				type: Function
				,default: () => {}
			}
			,actionRefuse: {
				type: Function
				,default: () => {}
			}
			,modalConfig: {
				type: Object
				,default: () => {}
			}
		}
		,methods: {
			btnsActions: function(tipo) {
				if (tipo == 1)
					this.$emit('actionConfirm');
				else
					this.$emit('actionRefuse');
			}
		}
		,computed: {
			hasSlot: function() {
				return !!this.$slots['default'];
			}
			,getModalConfig: function() {
				let config = {}

				if (this.modalConfig == null || Object.getOwnPropertyNames(this.modalConfig).length == 0) {
					config = {
						type: 'warning'
						,width:'30vw'
					}
				}else {
					config = this.modalConfig;

					if (!config.type)
						config.type='warning';

					if (!config.width)
						config.width='30vw';
				}

				return config;
			}
		}
	}
</script>