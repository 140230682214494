<template>
	<div>
		<Modal :options="{close:true}" @close="close_modal">
			<div class="title">Valijas - Solicitud</div>
			<div class="body">
				<ul class="nav nav-tabs mb-3">
					<li v-for="(tmpValija, index) in solicitud.valijas" :key="tmpValija.id" class="nav-item">
						<span :class="'nav-link'+(tmpValija.id == valija.id ? ' active' : '')" @click="cargar_valija(tmpValija)">{{ 'Valija '+(index+1) }}</span>
					</li>
				</ul>

				<div v-if="valija">
					<div class="row form-group">
						<label for="valija.valija_id" class="col-form-label col-sm-2">Valija ID</label>
						<div class="col-sm-4">
							<input v-model="valija.valija_id" type="text" name="valija.valija_id" id="valija.valija_id" class="form-control" readonly>
						</div>
						<label for="valija.pendiente" class="col-form-label col-sm-2">Pendiente</label>
						<div class="col-sm-4">
							<div class="custom-control custom-switch">
								<input v-model="valija.pendiente == 1" type="checkbox" name="garantia.propia" id="garantia.propia" class="custom-control-input" readonly>
								<label for="usuario_nuevo" class="custom-control-label" style="color: #B3B3B3">No | Sí</label>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<label for="valija.tag" class="col-form-label col-sm-2">Tag</label>
						<div class="col-sm-4">
							<input v-model="valija.tag" type="text" name="valija.tag" id="valija.tag" class="form-control" readonly>
						</div>
						<label for="valija.nombre" class="col-form-label col-sm-2">Nombre</label>
						<div class="col-sm-4">
							<input v-model="valija.nombre" type="text" name="valija.nombre" id="valija.nombre" class="form-control" readonly>
						</div>
					</div>
					<div class="row form-group">
						<label for="valija.descripcion" class="col-form-label col-sm-2">Descrición</label>
						<div class="col-sm-10">
							<textarea v-model="valija.descripcion" class="form-control" style="resize: none;" readonly></textarea>

						</div>
					</div>
				</div>
				<div v-else>
					<div class="row form-group">
						<div class="col-sm-12 text-center">Esta solicitud no tiene valijas</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'

	export default {
		components: {
			Modal
		}
		,props: {
			solicitud: {}
		}
		,data: function() {
			return {
				valija: {
					valija_id: null
					,pendiente: null
					,tag: null
					,nombre: null
					,descripcion: null
				}
			}
		}
		,methods: {
			close_modal: function() {
				this.$emit('close')
			}
		}
	}
</script>