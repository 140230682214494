<template>
	<div>
		<Modal :options="{close:true}" @close="cerrar_modal">
			<div class="title">Garantias - Solicitud</div>
			<div class="body">
				<ul class="nav nav-tabs mb-3">
					<li v-for="(tmpGarantia, index) in solicitud.garantias" :key="tmpGarantia.id" class="nav-item">
						<span :class="'nav-link'+(tmpGarantia.id == garantia.id ? ' active' : '')" @click="cargar_garantia(tmpGarantia)">{{ 'Garantia '+(index+1) }}</span>
					</li>
				</ul>

				<div class="row form-group">
					<label for="garantia.tipogarantia_id" class="col-form-label col-sm-2">Tipo garantia</label>
					<div class="col-sm-4">
						<input v-model="garantia.tipo_garantia" type="text" name="garantia.tipogarantia_id" id="garantia.tipogarantia_id" class="form-control" readonly>
					</div>
					<label for="garantia.descripcion" class="col-form-label col-sm-2">Descripción</label>
					<div class="col-sm-4">
						<input v-model="garantia.descripcion" type="text" name="garantia.descripcion" id="garantia.descripcion" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="garantia.propia" class="col-form-label col-sm-2">Propia</label>
					<div class="col-sm-4">
						<div class="custom-control custom-switch">
							<input  v-model="garantia.propia == 1" type="checkbox" name="garantia.propia" id="garantia.propia" class="custom-control-input" readonly>
							<label for="usuario_nuevo" class="custom-control-label" style="color: #B3B3B3">No | Sí</label>
						</div>
					</div>
					<label for="garantia.calle" class="col-form-label col-sm-2">Calle</label>
					<div class="col-sm-4">
						<input v-model="garantia.calle" type="text" name="garantia.calle" id="garantia.calle" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="garantia.colonia" class="col-form-label col-sm-2">Colonia</label>
					<div class="col-sm-4">
						<input v-model="garantia.colonia" type="text" name="garantia.colonia" id="garantia.colonia" class="form-control" readonly>
					</div>
					<label for="garantia.cp" class="col-form-label col-sm-2">CP</label>
					<div class="col-sm-4">
						<input v-model="garantia.cp" type="text" name="garantia.cp" id="garantia.cp" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="garantia.delegacion" class="col-form-label col-sm-2">Delegación</label>
					<div class="col-sm-4">
						<input v-model="garantia.delegacion" type="text" name="garantia.delegacion" id="garantia.delegacion" class="form-control" readonly>
					</div>
					<label for="garantia.entidad_id" class="col-form-label col-sm-2">Entidad</label>
					<div class="col-sm-4">
						<input v-model="garantia.entidad" type="text" name="garantia.entidad_id" id="garantia.entidad_id" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="garantia.valor" class="col-form-label col-sm-2">Valor</label>
					<div class="col-sm-4">
						<input v-model="garantia_valor_format" type="text" name="garantia.valor" id="garantia.valor" class="form-control" readonly>
					</div>
					<label for="garantia.status" class="col-form-label col-sm-2">Estatus</label>
					<div class="col-sm-4">
						<input v-model="garantia.status" type="text" name="garantia.status" id="garantia.status" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="area_construida" class="col-form-label col-sm-2">Área construida</label>
					<div class="col-sm-4">
						<InputCompuesto v-model="garantia.area_construida" label_right="m2" :readonly="true" name="area_construida" id="area_construida"/>
					</div>

					<label for="superficie_terreno" class="col-form-label col-sm-2">Superficie terreno</label>
					<div class="col-sm-4">
						<InputCompuesto v-model="garantia.superficie_terreno" label_right="m2" :readonly="true" name="superficie_terreno" id="superficie_terreno"/>
					</div>
				</div>
				<div class="row form-group">
					<label for="garantia.descripcion_valuacion_cliente" class="col-form-label col-sm-2">Descripción cliente</label>
					<div class="col-sm-4"><input v-model="garantia.descripcion_valuacion_cliente" type="text" name="garantia.descripcion_valuacion_cliente" id="garantia.descripcion_valuacion_cliente" class="form-control" readonly="readonly"></div>
					<label for="garantia.edad" class="col-form-label col-sm-2">Antigüedad</label>
					<div class="col-sm-4"><InputCompuesto v-model="garantia.edad" label_right="años" :readonly="true" name="garantia.edad" id="garantia.edad"/></div>
				</div>
				<div class="row form-group">
					<label for="garantia.recamaras" class="col-form-label col-sm-2">Recamaras</label>
					<div class="col-sm-4"><input v-model="garantia.recamaras" type="text" name="garantia.recamaras" id="garantia.recamaras" class="form-control" readonly="readonly"></div>
					<label for="garantia.banos" class="col-form-label col-sm-2">Baños</label>
					<div class="col-sm-4"><input v-model="garantia.banos" type="text" name="garantia.banos" id="garantia.banos" class="form-control" readonly="readonly"></div>
				</div>
				<div class="row form-group">
					<label for="garantia.medios_banos" class="col-form-label col-sm-2">Medios baños</label>
					<div class="col-sm-4"><input v-model="garantia.medios_banos" type="text" name="garantia.medios_banos" id="garantia.medios_banos" class="form-control" readonly="readonly"></div>
					<label for="garantia.estacionamientos" class="col-form-label col-sm-2">Estacionamientos</label>
					<div class="col-sm-4"><input v-model="garantia.estacionamientos" type="text" name="garantia.estacionamientos" id="garantia.estacionamientos" class="form-control" readonly="readonly"></div>
				</div>
				<div class="row form-group">
					<label for="garantia.valuacion" class="col-form-label col-sm-2">Valuación</label>
					<div class="col-sm-4"><InputCompuesto v-model="garantia.valuacion" label="$" :readonly="true" name="garantia.valuacion" id="garantia.valuacion" :formats="['moneyFormat']"/></div>
					<label for="garantia.valuacion_cliente" class="col-form-label col-sm-2">Valuación cliente</label>
					<div class="col-sm-4"><InputCompuesto v-model="garantia.valuacion_cliente" label="$" :readonly="true" name="garantia.valuacion_cliente" id="garantia.valuacion_cliente" :formats="['moneyFormat']"/></div>
				</div>
				<div class="row form-group">
					<label for="garantia.latitud" class="col-form-label col-sm-2">Latitud</label>
					<div class="col-sm-4"><input v-model="garantia.latitud" type="text" name="garantia.latitud" id="garantia.latitud" class="form-control" readonly="readonly"></div>
					<label for="garantia.longitud" class="col-form-label col-sm-2">Longitud</label>
					<div class="col-sm-4"><input v-model="garantia.longitud" type="text" name="garantia.longitud" id="garantia.longitud" class="form-control" readonly="readonly"></div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'
	import InputCompuesto from '@/components/Form/InputCompuesto'

	export default {
		components: {
			Modal, InputCompuesto
		}
		,props: {
			solicitud: {
				type: Object
				,default: () => ({

				})
			}
		}
		,data: function() {
			return {
				garantia: {
					tipo_garantia: null
					,descripcion: null
					,propia: null
					,calle: null
					,colonia: null
					,cp: null
					,delegacion: null
					,entidad: null
					,status: null
					,area_construida: null
					,superficie_terreno: null
					,descripcion_valuacion_cliente: null
					,edad: null
					,recamaras: null
					,banos: null
					,medios_banos: null
					,estacionamientos: null
					,valuacion: null
					,valuacion_cliente: null
					,latitud: null
					,longitud: null
				}
			}
		}
		,mounted: function() {
			this.garantia = this.solicitud.garantias[0];
		}
		,methods: {
			cerrar_modal: function() {
				this.$emit('close');
			}
		}
		,computed: {
			garantia_valor_format: function() {
				return '$'+this.$helper.moneyFormat(this.garantia.valor);
			}
		}
	}
</script>