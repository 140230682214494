<template>
	<div>
		<div class="row mt-4 mb-4">
			<div class="col-sm-12 text-right">
				<h1>Solicitudes</h1>
			</div>
		</div>

		<DataGrid :config="dataGridConfig" :data="solicitudes" :select="seleccionar" @actions="dataGridActions">
			<div class="row">
				<div class="col-sm-2">
					<button class="btn secondary-btn" @click="ver_detalles_modal">Ver detalles</button>
				</div>
				<div class="col-sm-2">
					<button class="btn warning-btn" @click="obtener_solicitudes">Recargar</button>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="ver_detalles" :options="{width: '85vw'}" @close="cerrar_detalles_modal">
			<div class="title">Detalles - Solicitud {{ solicitud.solicitud_id }}</div>
			<div class="body modal_solicitud_detalles">
				<div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<div :class="'col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2 mb-sm-1'+(!solicitud.core_id ? ' offset-sm-0 offset-md-0 offset-lg-0 offset-xl-2' : ' offset-xs-0 offset-sm-0 offset-md-0 offset-lg-2 offset-xl-4')">
						<button class="btn btn-primary btn-block" @click="credito_modal = 0">Ver solicitud/crédito</button>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2  mb-sm-1">
						<button class="btn btn-warning btn-block" @click="ver_ejercido = true">Ver ejercido</button>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-sm-1">
						<button class="btn btn-success btn-block" @click="ver_garantias_modal">Ver garantias</button>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-sm-1">
						<button class="btn btn-dark btn-block" @click="ver_valijas_modal">Ver valijas</button>
					</div>
					<div v-if="!solicitud.core_id" class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
						<button class="btn btn-info btn-block" @click="mostrar_modal_buscar_cliente">Buscar cliente en core</button>
					</div>
				</div>
				<fieldset class="container-fluid">
					<legend>Información de identidad</legend>

					<div class="row form-group">
						<label for="primer_nombre" class="col-sm-2">Primer nombre</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.primer_nombre" type="text" name="primer_nombre" id="primer_nombre" class="form-control">
						</div>
						<label for="segundo_nombre" class="col-sm-2">Segundo nombre</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.segundo_nombre" type="text" name="segundo_nombre" id="segundo_nombre" class="form-control">
						</div>
					</div>

					<div class="row form-group">
						<label for="primer_apellido" class="col-sm-2">Primer apellido</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.primer_apellido" type="text" name="primer_apellido" id="primer_apellido" class="form-control">
						</div>
						<label for="segundo_apellido" class="col-sm-2">Segundo apellido</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.segundo_apellido" type="text" name="segundo_apellido" id="segundo_apellido" class="form-control">
						</div>
					</div>

					<div class="row form-group">
						<label for="curp" class="col-sm-2">Curp</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.curp" type="text" name="curp" id="curp" class="form-control">
						</div>
						<label for="rfc" class="col-sm-2">RFC</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.rfc" type="text" name="rfc" id="rfc" class="form-control">
						</div>
					</div>

					<div class="row form-group">
						<label for="estado_civil" class="col-sm-2">Estado civil</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.estado_civil" type="text" name="estado_civil" id="estado_civil" class="form-control">
						</div>
						<label for="nacionalidad" class="col-sm-2">Nacionalidad</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.nacionalidad" type="text" name="nacionalidad" id="nacionalidad" class="form-control">
						</div>
					</div>

					<div class="row form-group">
						<label for="fecha_nacimiento" class="col-sm-2">Fecha nacimiento</label>
						 <div class="col-sm-4">
							<input v-model="solicitud.fecha_nacimiento" type="date" name="fecha_nacimiento" id="fecha_nacimiento" class="form-control">
						</div>
						<label for="sexo" class="col-sm-2">Sexo</label>
						 <div class="col-sm-4">
							<select v-model="solicitud.sexo" name="sexo" id="sexo" class="form-control">
								<option value="femenino">Femenino</option>
								<option value="masculino">Masculino</option>
							</select>
						</div>
					</div>

					<div class="row form-group">
						<label for="tipo_persona" class="col-sm-2">Tipo persona</label>
						<div class="col-sm-4">
							<select v-model="solicitud.tipo_persona" name="tipo_persona" id="tipo_persona" class="form-control">
								<option value="1">Fisica</option>
								<option value="2">Fisica con actividad empresarial</option>
								<option value="3">Moral</option>
							</select>
						</div>
					</div>

					<div v-if="solicitud.tipo_persona==3" class="row form-group">
						<label for="empresa_nombre" class="col-sm-2">Empresa Nombre</label>
						<div class="col-sm-4">
							<input v-model="solicitud.empresa_nombre" type="text" name="empresa_nombre" id="empresa_nombre" class="form-control">
						</div>

						<label for="empresa_rfc" class="col-sm-2">Empresa RFC</label>
						<div class="col-sm-4">
							<input v-model="solicitud.empresa_rfc" type="text" name="empresa_rfc" id="empresa_rfc" class="form-control">
						</div>
					</div>
				</fieldset>

				<fieldset class="container-fluid">
					<legend>Formas de contacto</legend>

					<div v-for="(contacto,index) in solicitud.contactos" :key="contacto.id" class="row form-group">
						<div class="col-sm-2">
							<label :for="'contacto_tipo_'+(index+1)" class="col-form-label col-sm-2">Tipo</label>
							<select v-model="contacto.tipo" :name="'contacto_tipo_'+(index+1)" :id="'contacto_tipo_'+(index+1)" class="form-control" @change="info_tipo_contacto.validado = 0" :readonly="contacto.block">
								<option v-for="info_tipo_contacto in catalogo_info_contacto" :key="info_tipo_contacto.id" :value="info_tipo_contacto.valor">{{ info_tipo_contacto.nombre }}</option>
							</select>
						</div>
						<div class="col-sm-6">
							<label :for="'contacto_valor_'+(index+1)" class="col-form-label col-sm-2">Valor</label>
							<div class="input-group mb-2">
						        <div v-if="contacto.tipo != 'email'" class="input-group-prepend">
						          <div class="input-group-text">+52</div>
						        </div>
					        	<input v-model="contacto.valor" type="text" class="form-control"  :name="'contacto_valor_'+(index+1)" :id="'contacto_valor_'+(index+1)" @change="contacto.validado = 0" :readonly="contacto.block">
					        	<!-- <div v-if="contacto.tipo != 'telefono' && contacto.tipo != 'telefono_oficina'" class="input-group-append">
									<button :class="'btn '+(contacto.validado == 1 ? 'btn-secondary' : 'btn-danger')" :disabled="contacto.validado == 1" @click="enviar_otp_destinatario(contacto.valor, contacto)">Enviar OTP</button>
								</div> -->
					    	</div>
						</div>
						<div class="col-sm-2 pt-4">
							<Radio v-model="contacto.principal" name="contacto_preferida" :id="'contacto_preferida_'+(index+1)" value="1" label="Forma de contacto preferida" />
						</div>
						<div v-if="false" class="col-sm-2 pt-4">
							<Radio v-model="contacto.facturas" name="contacto_factura" :id="'contacto_factura'+(index+1)" value="1" label="Enviar facturas" :disabled="contacto.tipo != 'email'"/>
						</div>
						<div class="col-sm-2 d-flex justify-content-center">
							<div class="text-center mr-4">
								<button class="btn secondary-btn btn-icon" title="Guardar contacto" @click="guardar_cambios_contacto(contacto)">
									<span class="icon" title="Guardar cambios"><i class="gg-check"></i></span>
								</button>
							</div>
							<div class="text-center">
								<button class="btn complementary-btn btn-icon" title="Eliminar contacto" @click="eliminar_contacto(contacto)">
									<span class="icon icon-trash" title="Eliminar contacto"><i class="gg-trash-empty"></i></span>
								</button>
							</div>
						</div>
					</div>
					<div class="row form-group">
						<div class="col-sm-3 offset-sm-9">
							<button class="btn btn-secondary btn-block" @click="contacto_nuevo.mostrar = true;">Agregar método de contacto</button>
						</div>
					</div>
				</fieldset>

				<div class="row form-group">
					<div class="col-sm-12">
						<fieldset>
							<legend>Direcciones</legend>

							<div class="accordion mb-4" id="listaDirecciones">
								<div v-for="(tmp_direccion, index) in solicitud.direcciones" :key="tmp_direccion.id" class="card">
									<div class="card-header" id="headingOne">
										<h2 class="mb-0">
											<button class="btn btn-link" type="button" data-toggle="collapse" :data-target="'#collapse'+index" aria-expanded="true" :aria-controls="'collapse'+index">
												{{ tmp_direccion.calle }}
											</button>
										</h2>
									</div>

									<div :id="'collapse'+index" :class="'collapse'+(index == 0 ? ' show' : '')" aria-labelledby="headingOne" data-parent="#listaDirecciones">
										<div class="card-body">
											<div class="row form-group">
												<label for="direccion_calle" class="col-form-label col-sm-3">Calle</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.calle" type="text" name="direccion_calle" :id="'direccion_calle'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label class="col-form-label col-sm-3">Número</label>
												<div class="col"><input v-model="tmp_direccion.no_interior" type="number" name="direccion_numero_interior" :id="'direccion_numero_interior'+index" class="form-control" placeholder="Número interior"></div>
												<div class="col"><input v-model="tmp_direccion.no_exterior" type="number" name="direccion_numero_exterior" :id="'direccion_numero_exterior'+index" class="form-control" placeholder="Número exterior"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_colonia" class="col-form-label col-sm-3">Colonia</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.colonia" type="text" name="direccion_colonia" :id="'direccion_colonia'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_delegacion_municipio" class="col-form-label col-sm-3">Delegación / Municipio</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.delegacion" type="text" name="direccion_delegacion_municipio" :id="'direccion_delegacion_municipio'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label for="direccion.estado" class="col-form-label col-sm-3">Estado</label>
												<div class="col-sm-9">
													<select v-model="tmp_direccion.estado" name="direccion.estado" id="direccion.estado" class="form-control">
														<option value="null">Selecciona un estado</option>
														<option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
													</select>
												</div>            
											</div>											
											<div class="row form-group">
												<label for="direccion_cp" class="col-form-label col-sm-3">CP</label>
												<div class="col-sm-9"><input v-model="tmp_direccion.cp" type="number" name="direccion_cp" :id="'direccion_cp'+index" class="form-control"></div>
											</div>
											<div class="row form-group">
												<label for="direccion_principal" class="col-form-label col-sm-3">Dirección principal</label>
												<div class="col-sm-3">
													<BSwitch v-model="tmp_direccion.principal" :id="'direccion_principal'+index" name="direccion_principal" texton="Sí" textoff="No" />
												</div>
												<label for="direccion_correspondencia" class="col-form-label col-sm-3">Dirección correspondencia</label>
												<div class="col-sm-3">
													<BSwitch v-model="tmp_direccion.correspondencia" :id="'direccion_correspondencia'+index" name="direccion_correspondencia" texton="Sí" textoff="No" />
												</div>
											</div>
											<div class="row form-group">
												<div class="col-sm-4 offset-sm-4"><button class="btn secondary-btn" @click="guardar_cambios_direccion(tmp_direccion.id)">Guardar cambios</button></div>
												<div class="col-sm-4"><button class="btn complementary-btn" @click="eliminar_direccion(tmp_direccion.id,tmp_direccion.principal)">Eliminar</button></div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row form-group">
								<div class="col-sm-3 offset-sm-9"><button class="btn btn-dark btn-block" @click="direccion.mostrar = true">Agregar dirección</button></div>
							</div>
						</fieldset>
					</div>
				</div>

			</div>
			<div class="footer">
				<div class="row col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 mb-sm-1">
						<button class="btn btn-info btn-block" @click="asignar_credito_cliente_existente">Asignar Solicitud Cliente Existente</button>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-3 offset-xs-0 offset-sm-0 offset-md-0 offset-lg-0 offset-xl-2 mb-sm-1">
						<button class="btn btn-success btn-block" @click="asignar_cliente_modal">Asignar Solcitud Cliente Nuevo</button>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-sm-1">
						<button class="btn btn-primary btn-block" @click="guardar_datos">Guardar</button>
					</div>
					<div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 mb-sm-1">
						<button class="btn btn-danger btn-block" @click="cerrar_detalles_modal">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Ejercido v-if="solicitud && ver_ejercido" :solicitud="solicitud" @close="ver_ejercido = false" />

		<Garantia v-if="garantia && ver_garantias" :solicitud="solicitud" @close="ver_garantias = false" />

		<Valija v-if="valija && ver_valijas" :solicitud="solicitud" @close="ver_valijas = false" />

		<Modal v-if="enviar_otp" :options="{width: '40vw'}" @close="cancelar_enviar_otp_destinatario">
			<div class="title">Enviar OTP</div>
			<div class="body">
				<div class="row form-group">
					<label for="otp.codigo" class="col-form-label col-sm-2">Código</label>
					<div class="col-sm-10"><input v-model="otp.codigo" type="text" name="otp.codigo" id="otp.codigo" class="form-control" :disabled="!otp.enviado"></div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6">
						<button class="btn principal-btn" :disabled="!otp.enviado" @click="validar_otp">Validar OTP</button>
					</div>
					<div class="col-sm-3">
						<button class="btn danger-btn" @click="cancelar_enviar_otp_destinatario">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="cambios_sin_guardar" :options="{width: '35vw',type:'error'}">
			<div class="title">Cambios sin guardar</div>
			<div class="body">
				<div class="row">
					<div class="col-sm-12">
						<p style="margin-bottom: 0px;" class="text-center">La solicitud presenta cambios sin guardar</p>
						<p style="margin-bottom: 0px;" class="text-center">¿Como deseas continuar?</p>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6">
						<button class="btn principal-btn" @click="cerrar_guardar_cambios">Guardar</button>
					</div>
					<div class="col-sm-3">
						<button class="btn danger-btn" @click="descartar_cambios">Descartar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="direccion.mostrar" @close="cancelar_agregar_direccion">
			<div class="title">Agregar una dirección</div>
			<div class="body">
				<div class="row form-group">
					<label for="credito_nuevo." class="col-form-label col-sm-2">Seleccionar origen</label>
					<div class="col-sm-10">
						<Radio v-model="direccion_garantias_tipo" id="direccion_origen_manual" name="direccion_origen" value="manual" label="Manual" :other="{display: 'inline'}" />
						<Radio v-model="direccion_garantias_tipo" id="direccion_origen_garantias" name="direccion_origen" value="garantias" label="De una garantia" :other="{display: 'inline'}" />
					</div>
				</div>

				<div  class="container-fluid">
					<div v-if="direccion_garantias_tipo == 'garantias'" class="row form-group">
						<label for="direccion_garantias" class="col-form-label col-sm-2">Garantias</label>
						<div class="col-sm-10">
							<select v-model="garantia_seleccionada" name="direccion_garantias" id="direccion_garantias" class="form-control">
								<option value="default">Selecciona una garantia</option>
								<option v-for="garantia in solicitud.garantias" :key="garantia.id" :value="garantia.id">{{ garantia.calle }}</option>
							</select>
						</div>
					</div>
					<div class="row form-group">
						<label for="direccion_calle" class="col-form-label col-sm-2">Calle</label>
						<div class="col-sm-10"><input v-model="direccion.calle" type="text" name="direccion_calle" id="direccion_calle" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label class="col-form-label col-sm-2">Número</label>
						<div class="col-sm-5"><input v-model="direccion.no_interior" type="number" name="direccion_numero_interior" id="direccion_numero_interior" class="form-control" placeholder="Número interior"></div>
						<div class="col-sm-5"><input v-model="direccion.no_exterior" type="number" name="direccion_numero_exterior" id="direccion_numero_exterior" class="form-control" placeholder="Número exterior"></div>
					</div>
					<div class="row form-group">
						<label for="direccion_colonia" class="col-form-label col-sm-2">Colonia</label>
						<div class="col-sm-10"><input v-model="direccion.colonia" type="text" name="direccion_colonia" id="direccion_colonia" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="direccion_delegacion_municipio" class="col-form-label col-sm-2">Delegación / Municipio</label>
						<div class="col-sm-10"><input v-model="direccion.delegacion" type="text" name="direccion_delegacion_municipio" id="direccion_delegacion_municipio" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="direccion.estado" class="col-form-label col-sm-2">Estado</label>
						<div class="col-sm-10">
							<select v-model="direccion.estado" name="direccion.estado" id="direccion.estado" class="form-control">
								<option value="null">Selecciona un estado</option>
								<option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
							</select>
						</div>            
					</div>


					<div class="row form-group">
						<label for="direccion_cp" class="col-form-label col-sm-2">CP</label>
						<div class="col-sm-10"><input v-model="direccion.cp" type="number" name="direccion_cp" id="direccion_cp" class="form-control"></div>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-2 offset-sm-8"><button class="btn btn-primary btn-block" @click="guardar_direccion">Guardar</button></div>
					<div class="col-sm-2"><button class="btn btn-danger btn-block" @click="cancelar_agregar_direccion">Cancelar</button></div>
				</div>
			</div>
		</Modal>

		<Modal v-if="contacto_nuevo.mostrar" :options="{width: '40vw'}" @close="cancelar_contacto_nuevo">
			<div class="title">Agragar método de contacto</div>
			<div class="body">
				<div class="row form-group">
					<label for="contacto_nuevo_tipo" class="col-form-label col-sm-3">Tipo</label>
					<div class="col-sm-9">
						<select v-model="contacto_nuevo.tipo" name="contacto_nuevo_tipo" id="contacto_nuevo_tipo" class="form-control">
							<option v-for="info_tipo_contacto in catalogo_info_contacto" :key="info_tipo_contacto.id" :value="info_tipo_contacto.valor">{{ info_tipo_contacto.nombre }}</option>
						</select>
					</div>
				</div>
				<div class="row form-group">
					<label for="contacto_nuevo_valor" class="col-form-label col-sm-3">Valor</label>
					<div class="col-sm-9">
						<div class="input-group">
					        <div v-if="contacto_nuevo.tipo != 'email'" class="input-group-prepend">
					          <div class="input-group-text">+52</div>
					        </div>
				        	<input v-model="contacto_nuevo.valor" type="text" class="form-control"  name="contacto_nuevo_valor" id="contacto_nuevo_valor" :placeholder="contacto_nuevo.tipo == 'email' ? 'correo@mail.com' : 'Número a 10 dígitos'" autocomplete="off">
				        	<!-- <div v-if="contacto_nuevo.tipo == 'email' || contacto_nuevo.tipo == 'celular'" class="input-group-append">
								<button class="btn btn-danger" :disabled="contacto_nuevo.validado == 1" @click="enviar_otp_destinatario(contacto_nuevo.valor, contacto_nuevo)">Enviar OTP</button>
							</div> -->
				    	</div>
				    </div>
				</div>
				<div class="row form-group">
					<label for="contacto_nuevo_preferido" class="col-form-label col-sm-3">Forma de contacto preferida</label>
					<div class="col-sm-9">
						<BSwitch v-model="contacto_nuevo.principal" id="contacto_nuevo_preferido" name="contacto_nuevo_preferido" texton="Sí" textoff="No" />
					</div>
				</div>
				<div v-if="false" class="row form-group">
					<label for="contacto_nuevo_facturas" class="col-form-label col-sm-3">Enviar facturas</label>
					<div class="col-sm-9">
						<BSwitch v-model="contacto_nuevo.facturas" id="contacto_nuevo_facturas" name="contacto_nuevo_facturas" texton="Sí" textoff="No" />
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-3 offset-sm-6"><button class="btn btn-primary btn-block" @click="crear_metodo_contacto">Agregar</button></div>
					<div class="col-sm-3"><button class="btn btn-danger btn-block" @click="cancelar_contacto_nuevo">Cancelar</button></div>
				</div>
			</div>
		</Modal>

		<Credito v-if="credito_modal!='close'" :solicitud="solicitud" :catalogos="catalogos" @close="credito_modal_cerrar" @solicitud_update="actualizar_solicitud"/>

		<BuscarCliente v-if="buscar_cliente_modal" :solicitud="solicitud" @close="buscar_cliente_modal=false" />


		<Confirm v-if="confirmar_eliminar_fondeador" title="¿Deseas eliminar el fondeador?" @actionRefuse="cancelar_eliminar_fondeador" @actionConfirm="quitar_fondeador(fondeador_id)">
			<div class="text-center">
				Estas a punto de eliminar definitivamente el fondeador, <br/><b>esta acción no se puede deshacer.</b><br/><br/>¿Deseas realmente eliminarlo?
			</div>
		</Confirm>
		<AsignarSolicitud v-if="modal_asignar_solicitud_cliente" @close="modal_asignar_solicitud_cliente=false" :clientes_encontrados="clientes" :solicitud="solicitud" @update="cerrar_modal_asignar_solicitud_cliente"/>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGridV2'
	import Modal from '@/components/Modal'
	import Radio from '@/components/Form/Radio'
	import BSwitch from '@/components/Form/Switch'
	import InputCompuesto from '@/components/Form/InputCompuesto'
	import Confirm from '@/components/Confirm'

	import api from '@/apps/clientes/api/clientes'
	import apiYunius from '@/apps/clientes/api/yunius'
	import apiAccount from '@/apps/clientes/api/account'

	import BuscarCliente from './Solicitud/BuscarCliente'
	import Credito from './Solicitud/Credito'
	import Ejercido from './Solicitud/Ejercido'
	import Garantia from './Solicitud/Garantia'
	import Valija from './Solicitud/Valija'
	import AsignarSolicitud from "./Solicitud/AsignarSolicitud";
	import config from "@/apps/clientes/config";

	import md5 from 'md5'

	export default {
		components: {
			DataGrid, Modal, Radio, BSwitch, InputCompuesto, Confirm, BuscarCliente, Credito, Ejercido, Garantia, Valija, AsignarSolicitud
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'solicitudes'
					,cols: {
						solicitud_id: 'Solicitud'
						,nombre_completo: 'Cliente'
						,created_at: 'Fecha envio'
					}
					,filters: {
						cols: {
							id: 'ID'
							,solicitud_id: 'Solicitud'
							,nombre: 'Nombre'
							,created_at: 'Fecha de creación'
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						created_at: function(val, row, vue) {
							return vue.$moment(val).format('YYYY-MM-DD HH:SS:DD');
						}
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,seleccionar: false
				,seleccionadas: []
				,solicitudes: []
				,ver_detalles: false
				,solicitud: null
				,solicitud_org: null
				,garantia: null
				,valija: null
				,asignar_cliente: false
				,cliente_nuevo: true
				,clientes_existentes: []
				,clientes_yunius: []
				,clientes_yunius_validos: []
				,enviar_otp: false
				,otp: {
					destinatario: null
					,codigo: null
					,enviado: false
					,tipo: null
				}
				,creditos: []
				,cod_acreditado_actual: null
				,id_cliente_actual: null
				,ver_ejercido: false
				,ver_garantias: false
				,ver_valijas: false
				,cambios_sin_guardar: false
				,catalogo_info_contacto: []
				,direccion_garantias: 'manual'
				,direccion: {
					mostrar: false
					,calle: null
					,no_interior: null
					,no_exterior: null
					,colonia: null
					,delegacion: null
					,estado: null
					,cp: null
				}
				,direccion_garantias_tipo: 'manual'
				,garantia_seleccionada: null
				,contacto_nuevo: {
					mostrar: false
					,tipo: 'email'
					,valor: null
					,validado: 0
					,principal: 0
					,facturas: 0
				}
				,credito_modal:'close'
				,buscar_cliente_modal: false
				,clientes_existentes: []
				,cliente_asignado: null
				,catalogos: []
				,modal_nuevo_fondeador: false
				
				,fondeador_id: null
				,confirmar_eliminar_fondeador: false
				,modal_asignar_solicitud_cliente:false
				,asignar_credito:null
				,clientes:[]
			}
		}
		,mounted: function() {
			this.obtener_catalogo_info();
			this.obtener_catalogos_core();
		}
		,methods: {
			obtener_solicitudes: async function() {
				let res = (await api.obtener_solicitudes(this.options)).data;

				this.solicitudes = res.data;
				this.dataGridConfig.paginator.pagina_actual = res.current_page;
				this.dataGridConfig.paginator.total_registros = res.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
			}
			,obtener_catalogo_info: async function() {
				this.catalogo_info_contacto = (await api.catalogos_obtener('metodo_contacto')).data.opciones;
				this.estados = (await api.listar_catalogos(config.catalogos.estados)).data.opciones;
			}
			,dataGridActions: function(tipo, data) {
				this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.obtener_solicitudes();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,cerrar_detalles_modal: function() {
				let solicitud = md5(JSON.stringify(this.solicitud));
				let solicitud_org = md5(JSON.stringify(this.solicitud_org));

				if (solicitud != solicitud_org) {
					this.cambios_sin_guardar = true;
					return;
				}

				this.ver_detalles = false;
				this.seleccionar = false;
			}
			,ver_garantias_modal: function() {
				if (this.solicitud.garantias.length == 0) {
					this.$helper.showMessage('Error','Esta solicitud no tiene garantias','error','alert');
					return false;
				}

				this.ver_garantias = true;
			}
			,ver_valijas_modal: function() {
				if (this.solicitud.valijas.length == 0) {
					this.$helper.showMessage('Error','La solicitud '+this.solicitud.solicitud_id+' no tiene valijas','error','alert');
					return false;
				}

				this.ver_valijas = true;
			}
			,mostrar_modal_buscar_cliente: function() {
				this.cliente_a_buscar = {
					curp: this.solicitud.curp
					,rfc: this.solicitud.rfc
				}

				this.buscar_cliente_modal = true;
			}
			,ver_detalles_modal: async function() {
				if (this.seleccionadas.length == 0)
					return this.$helper.showMessage('Error','Tienes que seleccionar una solicitud','error','alert');

				this.solicitud = (await api.obtener_detalles_solicitudes(this.seleccionadas[0].id)).data;
				this.$log.info('mostrar direccion ', this.solicitud);   				

				this.solicitud_org = Object.assign({},this.solicitud);
				this.garantia = this.solicitud.garantias[0];

				if (this.solicitud.valijas.length > 0)
					this.valija = this.solicitud.valijas[0];

				this.ver_detalles = true;
				// this.$log.info('solicitud', this.solicitud);
			}
			,obtener_catalogos_core: async function() {
				try {
					if (!this.$store.getters.getItem.core_catalogos) {
						this.catalogos = (await api.core_catalogos()).data;
						this.$store.commit('set', {core_catalogos: this.catalogos});
					}else
						this.catalogos = this.$store.getters.getItem.core_catalogos;
				}catch(e) {
					this.$log.info('Error', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,asignar_cliente_modal: async function() {
				try {
					let otps = 0,preferido=0,facturas = 0;

					this.solicitud.contactos.forEach(contacto =>  {
						// this.$log.info('conctacto',contacto);

						if (['email','celular'].indexOf(contacto.tipo) > -1 && contacto.validado == 1)
							otps++;

						if (contacto.principal == 1)
							preferido++;

						if (contacto.facturas == 1)
							facturas++;
					});

					// if (otps == 0)
					// 	return this.$helper.showMessage('Error','Tienes que validar al menos un medio de contacto (email ó celular)','error','alert');

					if (preferido == 0)
						return this.$helper.showMessage('Error','Tienes que definir un medio de contacto como preferido','error','alert');

					// if (facturas == 0)
					// 	return this.$helper.showMessage('Error','Tienes que difinir un medio de contacto para enviar las facturas','error','alert');

					// if (this.clientes_yunius.length == 0)
					// 	this.clientes_yunius = (await apiYunius.obtener_clientes()).data;

					// this.clientes_existentes = (await api.listar_usuarios(null,null)).data;

					// this.clientes_yunius_validos = [];
					// for(let i=0; i<this.clientes_yunius.length; i++) {
					// 	let encontrado = false;

					// 	for(let a=0; a<this.clientes_existentes.length; a++) {
					// 		if (this.clientes_yunius[i].cod_acreditado == this.clientes_existentes[a].cod_acreditado) {
					// 			encontrado = true;
					// 			a = this.clientes_existentes.length;
					// 		}
					// 	}

					// 	if (!encontrado)
					// 		this.clientes_yunius_validos.push(this.clientes_yunius[i]);
					// }

					
					await this.guardar_datos(false);

					let res = (await api.crear_usuario(this.solicitud.id)).data;
					this.asignar_cliente = false;
					this.cerrar_detalles_modal();
					this.obtener_solicitudes();
				}catch(e) {
					this.$log.error('Error',e);
					this.$helper.showAxiosError(e,'Error');

					this.solicitud = (await api.obtener_detalles_solicitudes(this.solicitud.id)).data;
				}
			},
			async asignar_credito_cliente_existente(){
				let cliente = null;

				if(this.solicitud.rfc){
					cliente = (await api.solicitudes_buscar_cliente('rfc', this.solicitud.rfc)).data;
					// this.$log.info('cliente rfc', cliente)
					if(cliente && cliente.length > 0)
						this.modal_asignar_solicitud_cliente = true;
				}
					
				if(this.solicitud.curp){
					cliente = (await api.solicitudes_buscar_cliente('curp', this.solicitud.curp)).data;
					// this.$log.info('cliente curp', cliente)
					if(cliente && cliente.length > 0)
						this.modal_asignar_solicitud_cliente = true;
				}
				
				if(cliente === null || cliente == undefined || cliente.length == 0){
					// this.$log.info('cliente', cliente)
					this.$helper.showMessage('Cliente No Encontrado','El cliente no esta registrado en nuestro sistema, es necesario asignar la solicitud a un cliente nuevo','info','alert'); 
				}

				this.clientes = cliente;
				cliente = null;
			}
			,guardar_datos: async function(showMessage) {
				if (typeof(showMessage) == 'undefiend')
					showMessage = true;

				let solicitud = Object.assign({}, this.solicitud);

				if (solicitud.garantias)
					delete solicitud.garantias;

				if (solicitud.ejercido)
					delete solicitud.ejercido;

				if (solicitud.valijas)
					delete solicitud.valijas;

				try {
					let res = (await api.actualizar_solicitud(solicitud.id, solicitud)).data;
					this.$log.info('guardarDatos: ', res);

					if (showMessage)
						this.$helper.showMessage('Datos guardados','Los datos de la solicitud se han guardado exitosamente');

					this.obtener_solicitudes();
					this.solicitud = res;
					this.solicitud_org = res;
				}catch(e) {
					this.$log.info('error: ',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,credito_modal_cerrar: function() {
				this.credito_modal = 'close';
			}
			,actualizar_solicitud: function(solicitud) {
				this.$log.info('Se actualiza solicitud');
				this.solicitud = solicitud;
			}
			,cerrar_guardar_cambios: async function() {
				await this.guardar_datos();

				this.cambios_sin_guardar = false;
				this.ver_detalles = false;
				this.seleccionar = false;
			}
			,descartar_cambios: function() {
				this.cambios_sin_guardar = false;
				this.ver_detalles = false;
				this.seleccionar = false;
			}
			,crear_metodo_contacto: async function() {
				try {
					await this.guardar_datos(false);

					let res = (await api.crear_contacto_solicitud(this.solicitud.id, this.contacto_nuevo)).data;

					this.$helper.showMessage('Exito!','Se creo el método de contacto correctamente');
					this.solicitud.contactos = res;
					this.cancelar_contacto_nuevo();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_contacto_nuevo: function() {
				this.contacto_nuevo = {
					mostrar: false
					,tipo: 'email'
					,valor: null
					,validado: 0
					,principal: 0
					,facturas: 0
				}
			}
			,cancelar_enviar_otp_destinatario: function() {
				this.otp = {
					destinatario: null
					,codigo: null
					,enviado: false
					,tipo: null
				};
				this.enviar_otp = false;
			}
			,enviar_otp_destinatario: async function(destinatario, contacto) {
				this.otp = {
					destinatario: destinatario
					,contacto: contacto
					,codigo: null
					,enviado: false
				};

				if (!this.otp.destinatario)
					return this.$helper.showMessage('Error','Tienes que especificar el destinatario de la OTP','error','alert');

				try {
					let res = (await apiAccount.enviar_otp(this.otp.destinatario)).data;

					if (res.success) {
						this.$helper.showMessage('OTP enviada','La OTP se envió correctamente');
						this.enviar_otp = true;
						this.otp.enviado = true;
					}else
						this.$helper.showMessage('Error','No se pudo enviar la OTP: '+res.message,'error','alert');
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,validar_otp: async function() {
				try {
					let res = (await apiAccount.validar_otp({destinatario: this.otp.destinatario, codigo: this.otp.codigo})).data;
					this.$log.info('res', res);

					if (res.success) {
						this.$helper.showMessage('OTP validada con exito','La OTP se valido correctamente');

						this.otp.contacto.validado = 1;

						await this.guardar_datos(false);
						this.cancelar_enviar_otp_destinatario();
					}else {
						this.otp.enviado = false;
						this.$helper.showMessage('Error','No se pudo enviar la OTP: '+res.message,'error','alert');
					}
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,guardar_cambios_contacto: async function(contacto) {
				try {
					await this.guardar_datos(false);

					await api.modificar_contacto_solicitud(this.solicitud.id, contacto.id, contacto);

					this.$helper.showMessage('Contacto guardado','Los datos del contacto se guardaron correctamente');

					this.ver_detalles_modal();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,eliminar_contacto: async function(contacto) {
				if (this.solicitud.contactos.length == 1)
					return this.$helper.showMessage('Error','Debes dejar al menos un método de contacto','error','alert');

				if (contacto.principal)
					return this.$helpre.showMessage('Error','No puedes eliminar un método de contacto preferido','error','alert');

				try {
					await this.guardar_datos(false);

					await api.eliminar_contacto_solicitud(this.solicitud.id, contacto.id);

					this.$helper.showMessage('Contacto eliminado','El contaco se a eliminado el contacto correctamente');

					this.ver_detalles_modal();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,guardar_direccion: async function() {
				try {
					await this.guardar_datos(false);

					let direcciones = (await api.guardar_direccion_solicitud(this.solicitud.id, this.direccion)).data;
					this.$log.info('guardarDireccion', direcciones);

					this.$helper.showMessage('Exito!','Se guardo la dirección correctamente');
					this.solicitud.direcciones = direcciones;
					this.cancelar_agregar_direccion();
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cancelar_agregar_direccion: function() {
				this.garantia_seleccionada = 'default';
				this.direccion_garantias_tipo = 'manual';

				this.direccion = {
					mostrar: false
					,calle: null
					,no_interior: null
					,no_exterior: null
					,colonia: null
					,delegacion: null
					,estado: null
					,cp: null
				}
			}
			,guardar_cambios_direccion: async function(id) {
				let direccion = null

				this.solicitud.direcciones.forEach(tmp => {
					if (tmp.id == id)
						direccion = this.$helper.clone(tmp);
				});

				if (!direccion)
					return this.$helper.showMessage('Error','No se encontró la solicitud a guardar');

				try {
					await this.guardar_datos(false);

					let res = (await api.actualizar_direccion_solicitud(this.solicitud.id, direccion.id, direccion)).data;

					this.$helper.showMessage('Exito!','La dirección se actualizó correctamente');
					this.solicitud.direcciones = res;
				}catch(e) {
					this.$log.info('err',e);
					this.$helper.showAxiosError(e, 'Error');
				}
			}
			,eliminar_direccion: async function(id, principal) {
				if (this.solicitud.direcciones.length == 1)
					return this.$helper.showMessage('Error','Tienes que dejar al menos una dirección','error','alert');

				if (principal && (principal == 1 || principal === true))
					return this.$helper.showMessage('Error','No puedes borrar una dirección principal','error','alert')

				try {
					await this.guardar_datos(false);

					let res = (await api.eliminar_direccion_solicitud(this.solicitud.id, id)).data;

					this.$helper.showMessage('Exito!','Dirección eliminada');
					this.solicitud.direcciones = res;
				}catch(e) {
					this.$log.info('err', e)
					this.$helper.showAxiosError(e,'Error');
				}
			},
			cerrar_modal_asignar_solicitud_cliente(){
				this.modal_asignar_solicitud_cliente = false;
				this.clientes = [];
				this.cerrar_detalles_modal();
				this.obtener_solicitudes();
			}
		}
		,watch: {
			garantia_seleccionada: function() {
				if (this.garantia_seleccionada) {
					this.solicitud.garantias.forEach(garantia => {
						if (garantia.id == this.garantia_seleccionada) {
							this.direccion = garantia;
						}
					})

					if (this.direccion && this.direccion.id)
						this.direccion.mostrar = true;
				}
			}
		}
	}
</script>

<style lang="scss">
	.icon-trash {
		width: 22px !important;
		height: 22px !important;
		display: block;
		padding-left: 6px;
		padding-top: 3px;
	}
</style>