<template>
	<div>
		<Modal :options="{close:true}" @close="cerrar_modal">
			<div class="title">Ejercido - Solicitud</div>
			<div class="body">
				<div class="row form-group">
					<label for="ejercido.monto_ejercido" class="col-form-label col-sm-2">Monto ejercido</label>
					<div class="col-sm-4">
						<input v-model="monto_ejercido_format" type="text" name="ejercido.monto_ejercido" id="ejercido.monto_ejercido" class="form-control" readonly>
					</div>
					<label for="ejercido.numero_comite" class="col-form-label col-sm-2">Número comité</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.numero_comite" type="text" name="ejercido.numero_comite" id="ejercido.numero_comite" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.tasa" class="col-form-label col-sm-2">Tasa</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.tasa" type="text" name="ejercido.tasa" id="ejercido.tasa" class="form-control" readonly>
					</div>
					<label for="ejercido.aforo" class="col-form-label col-sm-2">Aforo</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.aforo" type="text" name="ejercido.aforo" id="ejercido.aforo" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.fecha_comite" class="col-form-label col-sm-2">Fecha comité</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.fecha_comite" type="text" name="ejercido.fecha_comite" id="ejercido.fecha_comite" class="form-control" readonly>
					</div>
					<label for="ejercido.fecha_formalizacion" class="col-form-label col-sm-2">Fecha formalización</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.fecha_formalizacion" type="text" name="ejercido.fecha_formalizacion" id="ejercido.fecha_formalizacion" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.fecha_escriturarpp" class="col-form-label col-sm-2">Fecha escritura RPP</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.fecha_escriturarpp" type="text" name="ejercido.fecha_escriturarpp" id="ejercido.fecha_escriturarpp" class="form-control" readonly>
					</div>
					<label for="ejercido.notario" class="col-form-label col-sm-2">Notario</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.notario" type="text" name="ejercido.notario" id="ejercido.notario" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.valor_avaluo" class="col-form-label col-sm-2">Valor avaluo</label>
					<div class="col-sm-4">
						<input v-model="valor_avaluo_format" type="text" name="ejercido.valor_avaluo" id="ejercido.valor_avaluo" class="form-control" readonly>
					</div>
					<label for="ejercido.garantiaejercido_id" class="col-form-label col-sm-2">Garantia ejercido</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.tipo_garantia_ejercido" type="text" name="ejercido.garantiaejercido_id" id="ejercido.garantiaejercido_id" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.tipocredito_id" class="col-form-label col-sm-2">Tipo credito</label>
					<div class="col-sm-4">
						<textarea v-model="solicitud.ejercido.tipo_credito" class="form-control" style="resize: none;" name="ejercido.tipocredito_id" id="ejercido.tipocredito_id" readonly></textarea>
					</div>
					<label for="ejercido.plazo_otorgado" class="col-form-label col-sm-2">Plazo otorgado</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.plazo_otorgado" type="text" name="ejercido.plazo_otorgado" id="ejercido.plazo_otorgado" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.comision_apertura" class="col-form-label col-sm-2">Comisión apertura</label>
					<div class="col-sm-4">
						<input v-model="comision_apertura_format" type="text" name="ejercido.comision_apertura" id="ejercido.comision_apertura" class="form-control" readonly>
					</div>
					<label for="ejercido.monto_retiro" class="col-form-label col-sm-2">Monto retiro</label>
					<div class="col-sm-4">
						<input v-model="monto_retiro_format" type="text" name="ejercido.monto_retiro" id="ejercido.monto_retiro" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.monto_autorizado_comite" class="col-form-label col-sm-2">Monto autorizador comité</label>
					<div class="col-sm-4">
						<input v-model="monto_autorizado_comite_format" type="text" name="ejercido.monto_autorizado_comite" id="ejercido.monto_autorizado_comite" class="form-control" readonly>
					</div>
					<label for="ejercido.estatus" class="col-form-label col-sm-2">Estatus</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.estatus" type="text" name="ejercido.estatus" id="ejercido.estatus" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.entidad_federativa" class="col-form-label col-sm-2">Entidad federativa</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.entidad_federativa" type="text" name="ejercido.entidad_federativa" id="ejercido.entidad_federativa" class="form-control" readonly>
					</div>
					<label for="ejercido.fecha_pago_avaluo" class="col-form-label col-sm-2">Fecha pago avaluo</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.fecha_pago_avaluo" type="text" name="ejercido.fecha_pago_avaluo" id="ejercido.fecha_pago_avaluo" class="form-control" readonly>
					</div>
				</div>
				<div class="row form-group">
					<label for="ejercido.fecha_asignacion_notaria" class="col-form-label col-sm-2">Fecha asignación notaría</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.fecha_asignacion_notaria" type="text" name="ejercido.fecha_asignacion_notaria" id="ejercido.fecha_asignacion_notaria" class="form-control" readonly>
					</div>
					<label for="ejercido.tipo_negocio" class="col-form-label col-sm-2">Tipo negocio</label>
					<div class="col-sm-4">
						<input v-model="solicitud.ejercido.tipo_negocio" type="text" name="ejercido.tipo_negocio" id="ejercido.tipo_negocio" class="form-control" readonly>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import Modal from '@/components/Modal'

	export default {
		components: {
			Modal
		}
		,props: {
			solicitud: {
				type: Object
				,default: () => ({

				})
			}
		}
		,methods: {
			cerrar_modal: function() {
				this.$emit('close');
			}
		}
		,computed: {
			importe_solicitado_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.importe_solicitado);
			}
			,importe_seleccionado_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.importe_seleccionado);
			}
			,importe_pre_autorizado_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.importe_pre_autorizado);
			}
			,ingreso_mensual_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.ingreso_mensual);
			}
			,monto_ejercido_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.ejercido.monto_ejercido);
			}
			,valor_avaluo_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.ejercido.valor_avaluo);
			}
			,comision_apertura_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.ejercido.comision_apertura);
			}
			,monto_retiro_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.ejercido.monto_retiro);
			}
			,monto_autorizado_comite_format: function() {
				return '$'+this.$helper.moneyFormat(this.solicitud.ejercido.monto_autorizado_comite);
			}
		}
	}
</script>