let config = {}

switch(process.env.VUE_APP_ENV) {
	case 'local':
		config.catalogos = {
			metodo_contacto: 2,
			estados: 16,
			tipo_solicitud:15,
			motivos_ticket:17,
			areas_bpb:18,
			subareas_bpb:20,
		};
		config.asignacion = {
			asignar:0,
            reasignar:1,
            ya_asignado:2,
		};
		config.comentarios = {
			origen_administrador:0,
			para_cliente:1,
		};
		config.estatus_ticket = {
			resuelto:3,
		}
		config.documentos ={
			origen_administrador:0
		}

		break;
	case 'test':
		config.catalogos = {
			metodo_contacto: 2,
			estados: 5,
			tipo_solicitud:4,
			motivos_ticket:6,
			areas_bpb:7,
			subareas_bpb:8,
		}
		config.asignacion = {
			asignar:0,
            reasignar:1,
            ya_asignado:2,
		};
		config.comentarios = {
			origen_administrador:0,
			para_cliente:1,
		};
		config.estatus_ticket = {
			resuelto:3,
		}

		config.documentos ={
			origen_administrador:0
		}
		
		break;
	case 'production':
		config.catalogos = {
			metodo_contacto: 2,
			estados: 5,
			tipo_solicitud:4,
			motivos_ticket:6,
			areas_bpb:7,
			subareas_bpb:8,
		};
		config.asignacion = {
			asignar:0,
            reasignar:1,
            ya_asignado:2,
		};
		config.comentarios = {
			origen_administrador:0,
			para_cliente:1,
		};
		config.estatus_ticket = {
			resuelto:3,
		}

		config.documentos ={
			origen_administrador:0
		}

		break;
}

export default config;