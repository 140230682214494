import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_CLIENTES;

class Yunius
{
	obtener_clientes() {
		return axios.get(baseURL+'/yunius/clientes');
	}

	obtener_creditos(no_acreditado) {
		return axios.get(baseURL+'/yunius/'+no_acreditado+'/creditos');
	}
}

export default new Yunius();